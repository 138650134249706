<template>
  <div class="flag-icon">
    <img v-if="logo" :class="{ square: type == 'square' }"
      :src="
        'https://storage.googleapis.com/files.divatraffic.com/' +
          logo
      "
    />
    <img v-else
      :class="{ square: type == 'square' }"
      :src="
        'https://storage.googleapis.com/files.divatraffic.com/flags/' +
          code.toLowerCase() + '.png'
      "
    />
  </div>
</template>
<script>
export default {
  name: "country-flag",
  props: ['logo', 'code', 'type'],
};
</script>
<style scoped>
.flag-icon{
  display: inline;  
  padding-right: 5px;
}
img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid gray;
  cursor: pointer;
}
.square {
  border-radius: 0px;
  width: 30px;
}
</style>
