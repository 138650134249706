<script>
export default {
  methods: {
    boost(pack) {
      this.$router.push({
        name: "boost",
        params: { packageId: pack.id }
      });
    },
    deviceTypesForPack(pack) {
      var deviceIds = [];
      try {
        pack.bids.forEach(p => {
          if (p.targeting.device_types) {
            p.targeting.device_types.forEach(d => {
              deviceIds.indexOf(d) < 0 ? deviceIds.push(d) : null;
            });
          }
        });
      } catch (e) {
        try {
          Object.keys(pack.bids).forEach(k => {
            if (pack.bids[k].targeting.device_types) {
              pack.bids[k].targeting.device_types.forEach(d => {
                deviceIds.indexOf(d) < 0 ? deviceIds.push(d) : null;
              });
            }
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn("cant load device types for pack bids");
        }
      }
      var devices = [];
      deviceIds.forEach(d => {
        if (d && this.getDeviceTypes.length)
          devices.push(this.getDeviceTypeById(d));
      });
      return devices
        .filter(d => {
          return this.deviceIcons.find(i => {
            return i.name == d.name;
          });
        })
        .map(d => {
          return this.deviceIcons.find(i => {
            return i.name == d.name;
          });
        });
    },
    countryCodes(pack) {
      var countryIds = [];
      try {
        pack.bids.forEach(p => {
          if (p.targeting.countries) {
            p.targeting.countries.forEach(d => {
              countryIds.indexOf(d) < 0 ? countryIds.push(d) : null;
            });
          }
        });
      } catch (e) {
        try {
          Object.keys(pack.bids).forEach(k => {
            if (pack.bids[k].targeting.countries) {
              pack.bids[k].targeting.countries.forEach(d => {
                countryIds.indexOf(d) < 0 ? countryIds.push(d) : null;
              });
            }
          });
        } catch (e) {
          console.warn("cant load pack for countries");
        }
      }
      var countries = [];
      countryIds.forEach(d => {
        if (d && this.getCountries && this.getCountries.length) {
          countries.push(this.getCountryById(d));
        }
      });
      var result = countries.map(c => {
        return c.code.toLowerCase();
      });
      return { codes: result, more: result.length > 3 };
    }
  }
};
</script>