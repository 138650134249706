<template>
  <world-map
    v-if="pack"
    class="vector-map worldMapHeight"
    :map-data="mapData"
  ></world-map>
</template>
<script>
import { mapGetters } from "vuex";
import WorldMap from "@/components/WorldMap/WorldMap.vue";
import PlanMixin from "@/mixins/Plan/PlanMixin.vue";
export default {
  props: {
    pack: Object
  },
  components: {
    WorldMap
  },
  mixins: [PlanMixin],
  watch: {
    pack() {
      this.$forceUpdate();
    }
  },
  computed: {
    ...mapGetters("Country", [
      "getAllCountryCodesForMap",
      "getCountryCodes",
      "getCountryById",
      "getCountries"
    ]),

    mapData() {
      if (this.pack) {
        let packCodes = this.countryCodes(this.pack)
        if (packCodes.codes.length) {
          var data = packCodes
            .codes.filter(e => {
              return this.getCountryCodes.find(d => {
                return e.toUpperCase() == d["alpha-2"];
              });
            })
            .map(c => {
              return this.getCountryCodes.find(d => {
                return c.toUpperCase() == d["alpha-2"];
              })["alpha-3"];
            });
          var map = {};
          data.forEach(d => {
            map[d] = 2000;
          });
          return map;
        } else {
          return this.getAllCountryCodesForMap;
        }
      } else {
        return this.getAllCountryCodesForMap;
      }
    }
  },
  methods: {
    createMapDataForPackage(pack) {
      if (this.countryCodes(pack).codes.length) {
        var data = this.countryCodes(pack)
          .codes.filter(e => {
            return this.getCountryCodes.find(d => {
              return e.toUpperCase() == d["alpha-2"];
            });
          })
          .map(c => {
            return this.getCountryCodes.find(d => {
              return c.toUpperCase() == d["alpha-2"];
            })["alpha-3"];
          });
        var map = {};
        data.forEach(d => {
          map[d] = 2000;
        });
        return map;
      } else {
        return this.getAllCountryCodesForMap;
      }
    }
  }
};
</script>
<style scoped>
.worldMapHeight {
  max-height: 100px;
  max-width: 100%;
  min-height: 100px;
  min-width: 100%;
}
</style>