<template>
  <div class="card card-style bid-card-text">
    <div class="card-header bg-gradient-pink text">
      <div class="row">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.bids')}}:</span>
        </div>
        <div class="col-6 text-right">
          <span class="text-right">{{ bidValue }}</span>
        </div>
      </div>
    </div>
    <div class="card-body bg-gradient-pink">
      <div class="row">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.networks')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="!bid.targeting.networks || bid.targeting.networks.length == 0"
        >
          <span>
            {{getTrans('messages.all_networks')}}
          </span>
        </div>
        <div class="col-6 text-right" v-else>
          <div
            class="row "
            v-for="network in bid.targeting.networks"
            :key="network"
          >
            <span>{{ capitalizeFirstLetter(targetingData.networks[network]) }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.countries')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="!bid.targeting.countries || bid.targeting.countries.length == 0"
        >
          <span class="text">
            {{getTrans('messages.all_countries')}}
          </span>
        </div>
        <div class="col-6 text-right" v-else>
          <div
            class="row "
            v-for="country in bid.targeting.countries"
            :key="country"
          >
            <span>{{ capitalizeFirstLetter(targetingData.countries[country]) }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.browsers')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="!bid.targeting.browsers || bid.targeting.browsers.length == 0"
        >
          <span class="text">
            {{getTrans('messages.all_browsers')}}
          </span>
        </div>
        <div class="col-6 text-right" v-else>
          <div
            class="row "
            v-for="browser in bid.targeting.browsers"
            :key="browser"
          >
            <span>{{ capitalizeFirstLetter(targetingData.browsers[browser]) }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.os')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="!bid.targeting.os || bid.targeting.os.length == 0"
        >
          <span class="text">
            {{getTrans('messages.all_os')}}
          </span>
        </div>
        <div class="col-6 text-right" v-else>
          <div class="row " v-for="os in bid.targeting.os" :key="os">
            <span>{{ capitalizeFirstLetter(targetingData.os[os]) }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.sources')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="!bid.targeting.sources || bid.targeting.sources.length == 0"
        >
          <span>
            <span class="text">
            {{getTrans('messages.all_sources')}}
            </span></span
          >
        </div>
        <div class="col-6 text-left" v-else>
          <div
            class="row  text-right"
            v-for="source in bid.targeting.sources"
            :key="source"
          ><span>{{ capitalizeFirstLetter(targetingData.sources[source]) }}</span></div
          >
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.device_types')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="
            !bid.targeting.device_types ||
              bid.targeting.device_types.length == 0
          "
        >
          <span class="text">
            {{getTrans('messages.all_device_types')}}
          </span>
        </div>
        <div class="col-6 text-right" v-else>
          <div
            class="row "
            v-for="deviceType in bid.targeting.device_types"
            :key="deviceType"
          >
          <span>{{capitalizeFirstLetter(targetingData.device_types[deviceType])}}</span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-left">
          <span>{{getTrans('messages.device_brands')}}:</span>
        </div>
        <div
          class="col-6 text-right"
          v-if="
            !bid.targeting.device_brands ||
              bid.targeting.device_brands.length == 0
          "
        >
          <span class="text">
            {{getTrans('messages.all_device_brands')}}
          </span>
        </div>
        <span class="col-6 text-right" v-else>
          <div
            class="row "
            v-for="brand in bid.targeting.device_brands"
            :key="brand"
          ><span>{{
              capitalizeFirstLetter(targetingData.device_brands[brand])
            }}</span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import StringFormatter from "@/mixins/StringFormatter.vue";
export default {
  mixins: [StringFormatter],
  props: { bid: Object, targetingData: Object },
  computed: {
    bidValue() {
      return this.bid.bid ? this.toDollarsWithDigits(this.bid.bid) : 0;
    }
  },
};
</script>
<style scoped>
.bid-card-text {
  color: white;
}
</style>
